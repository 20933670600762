.graphql-voyager {
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  // Schema title background and border
  .node .type-title polygon {
    fill: #a52c49;
    stroke: #a52c49;
  }

  // Schema table border
  .node polygon {
    stroke: #a52c49;
  }

  // Schema relation link
  .type-link {
    fill: #a52c49;
  }

  // Hovered arrows
  .edge.highlighted path:not(.hover-path),
  .edge.hovered path:not(.hover-path),
  .edge:hover path:not(.hover-path) {
    stroke: #a52c49;
    stroke-width: 3;
  }

  .edge.highlighted polygon,
  .edge.hovered polygon,
  .edge:hover polygon {
    fill: #a52c49;
    stroke: #a52c49;
  }

  // Hide type description
  .doc-panel {
    box-shadow: 0 2px 5px 0 rgb(0, 0, 0, 0.5);
    .description-box.-doc-type {
      display: none;
    }
    .type-details {
      .description-box {
        display: block;
      }
    }
  }

  // Powered by voyager.
  .powered-by {
    display: none;
  }

  // Move loading box left
  .loading-box {
    left: 370px;
  }

  // Root query label color
  .typelist-item.-root .type-name:after {
    background: #a52c49;
  }

  .typelist-item {
    margin-bottom: 3px;
  }

  .type-doc {
    margin-top: 0px;
    > .doc-navigation {
      padding: 5px 20px 5px 15px;
      min-height: unset;
      border-bottom: none;

      .header {
        display: none;
        font-size: 20px;
        color: #212121;
        font-weight: normal;
      }

      .active {
        color: #a52c49;
      }

      .back {
        display: flex;
        align-items: center;
      }
    }
  }

  .doc-panel {
    width: 370px;
    padding-bottom: 5px;

    > .contents {
      padding-top: 80px;
      border-right: none;
      margin-left: 15px;
    }
  }

  .search-box-wrapper {
    div:after {
      border-color: #a52c49;
    }
  }
  .setting-change-root {
    margin-top: 7px;
    div:after {
      border-color: #a52c49;
    }
  }

  > .menu-content {
    position: absolute;
    top: 0px;
    left: 0px;
    margin-left: 15px;
    z-index: 100;
    bottom: auto;
    width: 340px;
    padding: 15px;
    --webkit-box-shadow: none;
    box-shadow: none;
    border: none;

    .setting-other-options {
      display: flex;
      align-items: center;
      margin-left: -6px;
      color: #212121;

      span {
        display: none;
      }

      label {
        display: none;
      }

      :nth-last-child(-n + 2) {
        display: inline-flex;
      }

      [for='showLeafFields'] {
        margin: 0;
      }
      svg {
        color: #a52c49;
      }

      [class*='MuiTouchRipple-root'] {
        display: none;
      }
    }
  }

  .type-info-popover {
    left: 355px;
  }

  .doc-category > .item:nth-child(odd) {
    background-color: #fff;
  }

  .other-search-results.doc-category {
    .field-name {
      color: #f50057;
      font-size: 14px;
    }
  }

  .doc-category {
    .title {
      text-transform: uppercase;
      color: #424242;
      font-size: 14px;
      border-bottom: none;
    }

    .item.-selected {
      background-color: rgba(255, 0, 0, 0.18);
      border-left: 3px solid #a52c49;
    }
    .item:hover {
      background-color: rgba(255, 0, 0, 0.18);
    }
  }

  .type-name.-input-obj,
  .type-name.-object {
    color: #ac003d;
  }

  .eye-button {
    margin-left: 2px;
    &:hover {
      background: transparent !important;
    }
    [class*='MuiTouchRipple-root'] {
      display: none;
    }
  }

  #svg-pan-zoom-controls path,
  .eye-button svg path:not([fill]),
  .loading-animation path {
    fill: #a52c49;
  }
  .loading-animation h1 {
    color: #a52c49;
  }
}
